// extracted by mini-css-extract-plugin
export var loginForm = "LoginForm-module--loginForm--kEXmF";
export var loginForm__cta = "LoginForm-module--loginForm__cta--uRu2A";
export var loginForm__ctaArrw = "LoginForm-module--loginForm__ctaArrw--95Zdo";
export var loginForm__ctaLoader = "LoginForm-module--loginForm__ctaLoader--1T7yh";
export var loginForm__ctaSec = "LoginForm-module--loginForm__ctaSec--f-qN1";
export var loginForm__emailFrm = "LoginForm-module--loginForm__emailFrm--MKCNo";
export var loginForm__emailFrm__inpSec = "LoginForm-module--loginForm__emailFrm__inpSec--uQPF8";
export var loginForm__footer = "LoginForm-module--loginForm__footer--vZyiC";
export var loginForm__hdng = "LoginForm-module--loginForm__hdng--G3hEd";
export var loginForm__para = "LoginForm-module--loginForm__para--LNvT+";
export var loginForm__subHdng = "LoginForm-module--loginForm__subHdng--VKRRe";