import React, { useState } from "react";
import { navigate } from "gatsby";
// seo
import Seo from "../components/Seo";

// api actions
import { doValidateDoctorEmailId } from "../actions/doctorReferralActions";

// components
import Header from "../components/DoctorReferral/Header/Header";
import LoginForm from "../components/DoctorReferral/LoginForm/LoginForm";
import FailureToast from "../components/Toasts/FailureToast";

const DoctorReferralLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFailureToast, setShowFailureToast] = useState(false); // show toast message when referral lead already exist in our system.

  const handleDoctorLogin = async data => {
    try {
      // api call -> to get doctor details based on email id
      setLoading(true);
      const response = await doValidateDoctorEmailId(data);
      setLoading(false);
      if (response.data) {
        navigate(`/doctor-referral-home/?email=${response.data.email}`);
      } else {
        setShowFailureToast(true);
        setError(
          "Your email isn’t registered with twin, please use registered email address."
        );
      }
    } catch (error) {
      console.error("Doctor referral email id server error", error);
      setError(
        "Your email isn’t registered with twin, please use registered email address."
      );
      setLoading(false);
      setShowFailureToast(true);
      return;
    }
  };

  return (
    <div className="bgLightgray" style={{ minHeight: "100vh" }}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      <Header showQrFaqIcon={false} LinkTo="/" />
      <LoginForm
        handleDoctorLogin={handleDoctorLogin}
        loading={loading}
        error={error}
      />

      {/* Toast components */}
      <FailureToast
        position="top-center"
        showFailureToast={showFailureToast}
        delay={5000}
        autohide={true}
        text="Your email isn’t registered with twin, please use registered email address."
        onClose={() => setShowFailureToast(false)}
      />
    </div>
  );
};

export default DoctorReferralLoginPage;
