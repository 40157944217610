import React from "react";
import { useForm } from "react-hook-form";
import { StaticImage } from "gatsby-plugin-image";
import Loader from "react-loader-spinner";

// constant
import constants from "../../../constants";

// services
import { sendToCT } from "../../../services/Clevertap";

// css
import * as styles from "./LoginForm.module.css";

const LoginForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { contact } = constants.DOCTOR_REFERRAL;

  const onSubmit = data => {
    if (data.email) props.handleDoctorLogin(data.email);

    // clevertap event
    sendToCT("Doctor_referral_login_initiated", {
      action: "Clicked on Get Started button",
      email: data.email,
    });
  };

  return (
    <div className={styles.loginForm}>
      {/* Heading */}
      <div className={styles.loginForm__hdng}>
        <p>Referral login</p>
      </div>
      {/* Sub-Heading */}
      <div className={styles.loginForm__subHdng}>
        <p>Enter registered email</p>
      </div>
      {/* Paragraph */}
      <div className={styles.loginForm__para}>
        <p>
          Enter the your registered email id to <br></br> newly refer and track
          your referrals
        </p>
      </div>
      {/* Email I/P Form */}
      <div className={styles.loginForm__emailFrm}>
        <form name="doctorEmail" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12 mt-3">
              <div
                className={`form-group ${styles.loginForm__emailFrm__inpSec}`}
              >
                <label htmlFor="email">Your email id*</label>
                <input
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  placeholder="Enter your registered email id"
                  id="email"
                  type="email"
                  {...register("email", {
                    required: "Please enter valid email address",
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                      message: "Please enter your valid registered email id",
                    },
                  })}
                />
                {errors.email && (
                  <span className="invalid-feedback">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* CTA */}
          <div className={`col-md-12 ${styles.loginForm__ctaSec}`}>
            <div className="buttonClass">
              <button type="submit" className={styles.loginForm__cta}>
                <div className="bookBtn" id="book-now">
                  <span
                    className={`d-flex align-items-center justify-content-center position-relative`}
                  >
                    <span className={`bookBtn`}>Get started</span>
                    <span
                      className={`icon-arrow-forward ${styles.loginForm__ctaArrw}`}
                      aria-hidden="true"
                    ></span>
                  </span>
                </div>
                <span className={styles.loginForm__ctaLoader}>
                  <Loader
                    type="ThreeDots"
                    color="#FFF"
                    height={30}
                    width={30}
                    visible={props.loading}
                  />
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* Footer Text */}
      <div className={styles.loginForm__footer}>
        <StaticImage
          src="../../../images/doctor/contact_support.png"
          alt=""
          className="img-fluid"
          placeholder="blurred"
          quality={90}
          style={{
            width: "23px",
            height: "27px",
          }}
        />
        <p>
          Forgot your email id? reach out to<br></br>{" "}
          <a href={`mailto:${contact.email}`} className="navLink">
            <span>drcare@twinhealth.com</span>
          </a>{" "}
          for assistance
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
